/* eslint-disable max-lines */
import { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Link,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';

import { RepublicLogo } from '@r-client/republic/feature/brand';
import { NotificationsDropdownMenuButton } from '@r-client/republic/feature/notifications';
import { MaybeValue, Viewer } from '@r-client/shared/feature/auth';
import {
  ArrowUpIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  GlobeIcon,
} from '@r-client/shared/ui/chakra-ui-icons';
import { useNoPageScroll } from '@r-client/shared/util/core';

import { INavigationData } from '../../config/types';
import { HEADER_THEMES } from '../../types';
import { getBackgroundColor } from '../../utils/get-background-color';
import { getLogo } from '../../utils/get-logo';
import { getSignInPath } from '../../utils/get-signin-path';
import { getTextColor } from '../../utils/get-text-color';
import { useHeaderMenuLinkTrack } from '../../utils/use-header-menu-link-track';
import { useSelectedNavigationEntry } from '../../utils/use-selected-navigation-entry';
import { MessagesMenu } from '../messages-menu/messages-menu';
import { MobileSearch } from '../mobile-search/mobile-search';
import { MobileUserMenu } from '../mobile-user-menu/mobile-user-menu';
import { RegionSwitcherMobile } from '../region-switcher/region-switcher-mobile';
import { IRegionSwitcherSettings } from '../region-switcher/types';

interface IMobileHeaderProps {
  navigationData: INavigationData[];
  theme: HEADER_THEMES;
  overlay: boolean;
  sticky: boolean;
  regionSwitcherSettings?: IRegionSwitcherSettings;
  user: {
    viewer: MaybeValue<Viewer>;
    isLoading?: boolean;
  };
}

export const MobileHeader = ({
  navigationData,
  theme,
  overlay,
  sticky,
  user,
  regionSwitcherSettings,
}: IMobileHeaderProps) => {
  const { isOpen, onToggle } = useDisclosure();
  const { disablePageScroll, enablePageScroll } = useNoPageScroll();
  const path = useRouter().pathname;
  const { track: trackHeaderMenuLinkClick } = useHeaderMenuLinkTrack();
  const [allowContentOverflow, setAllowContentOverflow] = useState(false);

  const { selectedNavigationEntry, onSelect } = useSelectedNavigationEntry(0);

  const navigationEntryItems =
    selectedNavigationEntry !== undefined
      ? navigationData[selectedNavigationEntry].items
      : [];

  const hamburgerMenuColor = isOpen ? 'grey.600' : getTextColor(theme, '600');

  const handleSearchToggle = (isSearchOpen: boolean) => {
    // https://stackoverflow.com/questions/69589924/ios-15-minimized-address-bar-issue-with-fixed-position-full-screen-content
    if (isSearchOpen) {
      setAllowContentOverflow(true);
      document.documentElement.style.height = '100vh';
      document.body.style.position = 'fixed';
    } else {
      setAllowContentOverflow(false);
      document.documentElement.style.height = '';
      document.body.style.position = '';
    }
  };

  useEffect(() => {
    if (isOpen) {
      // prevents scrolling the main content when the mobile menu is open
      disablePageScroll();
    } else {
      enablePageScroll();
    }
  }, [isOpen]);

  return (
    <Flex
      display={{ base: 'flex', md: 'none' }}
      zIndex="sticky"
      bgColor={isOpen ? 'white' : getBackgroundColor(theme)}
      position={isOpen ? 'fixed' : 'relative'}
      width="100%"
      flexDir="column"
      top="0"
      {...(overlay && !isOpen && overlayStyles)}
      {...(sticky && !isOpen && stickyHeaderStyles)}
    >
      <Flex
        px={isOpen ? '4' : '6'}
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="grey.100"
        boxSizing="content-box"
        height="16"
      >
        <Box py="3">
          {!isOpen && (
            <RepublicLogo
              type={getLogo(theme)}
              logoProps={{ height: '30', width: '122' }}
            />
          )}
        </Box>
        {isOpen && (
          <Flex gap="12">
            {navigationData.map((navItem, index) => (
              <Box
                role="button"
                tabIndex={1}
                py="19px"
                color={isOpen ? 'grey.900' : getTextColor(theme)}
                key={navItem.navigationEntry}
                borderBottom="2px solid transparent"
                {...(selectedNavigationEntry === index && {
                  borderBottomColor: 'blue.500',
                })}
                onClick={() => onSelect(index)}
              >
                {navItem.navigationEntry}
              </Box>
            ))}
          </Flex>
        )}

        {!user.isLoading && user.viewer?.info && !isOpen && (
          <Flex ml="auto" alignItems="center" gap="4">
            <MessagesMenu
              unreadMessages={user.viewer?.info?.unreadMessagesCount}
              theme={theme}
            />
            <NotificationsDropdownMenuButton
              color={getTextColor(theme)}
              mobile
              unreadNotificationsCount={
                user.viewer?.info?.unreadNotificationsCount ?? 0
              }
            />
          </Flex>
        )}

        {!user.isLoading && !user.viewer?.info && !isOpen && (
          <Link
            ml="auto"
            href={getSignInPath(path)}
            _hover={{
              textDecoration: 'none',
              color: 'blue.500',
            }}
          >
            <Text color={getTextColor(theme)}>Log in</Text>
          </Link>
        )}

        <Box
          role="button"
          ml={isOpen ? 'auto' : '5'}
          position="relative"
          width="20px"
          height="20px"
          onClick={onToggle}
        >
          <Box
            position="absolute"
            top="8px"
            width="100%"
            height="2px"
            backgroundColor={isOpen ? 'transparent' : hamburgerMenuColor}
            borderRadius="4px"
            transform={isOpen ? 'rotate(45deg)' : 'initial'}
            transition="transform 300ms ease 0s, background-color 0s cubic-bezier(0.6, 0, 0.15, 1) 0s;"
            _before={{
              display: 'block',
              position: 'absolute',
              width: '100%',
              height: '2px',
              backgroundColor: hamburgerMenuColor,
              borderRadius: '4px',
              content: '""',
              transform: isOpen ? 'translateY(0)' : 'translateY(-8px)',
              transition:
                'transform 300ms ease 0s, background-color 0s cubic-bezier(0.6, 0, 0.15, 1) 0s',
            }}
            _after={{
              display: 'block',
              position: 'absolute',
              width: '100%',
              height: '2px',
              backgroundColor: hamburgerMenuColor,
              borderRadius: '4px',
              content: '""',
              transform: isOpen
                ? 'translateY(0px) rotate(-90deg)'
                : 'translateY(8px) rotate(0deg)',
              transition:
                'transform 300ms ease 0s, background-color 0s cubic-bezier(0.6, 0, 0.15, 1) 0s',
            }}
          ></Box>
        </Box>
      </Flex>
      {isOpen && (
        <Flex
          background="white"
          zIndex="modal"
          flexDirection="column"
          flex="1"
          width="100vw"
          overflow={allowContentOverflow ? 'visible' : 'auto'}
          height="calc(100vh - 65px)"
          top="65px"
          position="fixed"
          paddingBottom="200px"
        >
          <Accordion defaultIndex={[0]} allowMultiple variant="republic">
            {navigationEntryItems.map((entry, index) => (
              <AccordionItem key={`entry_${index}`}>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton
                      _hover={{ bg: 'white' }}
                      fontWeight="semibold"
                      px="4"
                      py="5"
                    >
                      <Box as="span" flex="1" textAlign="left">
                        {entry.name}
                      </Box>
                      {isExpanded ? (
                        <ChevronUpIcon color="grey.600" boxSize="5" />
                      ) : (
                        <ChevronDownIcon color="grey.600" boxSize="5" />
                      )}
                    </AccordionButton>

                    <AccordionPanel
                      py="0"
                      display="flex"
                      flexDir="column"
                      gap="5"
                    >
                      {entry.showMobileSearch && (
                        <MobileSearch
                          onSearchOpen={() => handleSearchToggle(true)}
                          onSearchClose={() => handleSearchToggle(false)}
                        />
                      )}
                      {entry.subItems.map((subItem, subIndex) => (
                        <Box
                          key={`subItem_${subIndex}`}
                          _last={{
                            pb: '9',
                            borderBottom: '1px solid',
                            borderBottomColor: 'grey.100',
                          }}
                        >
                          <Link
                            _hover={{ textDecoration: 'none' }}
                            href={subItem.href}
                            isExternal={subItem.isExternal}
                            onClick={() =>
                              trackHeaderMenuLinkClick(
                                subItem.analyticsLabel ?? '',
                                navigationData[selectedNavigationEntry!]
                                  .navigationEntry
                              )
                            }
                          >
                            <Flex
                              justifyContent="space-between"
                              position="relative"
                            >
                              <Text
                                color="grey.900"
                                mr="1"
                                letterSpacing="less-tight"
                              >
                                {subItem.title}
                              </Text>
                              {subItem.isExternal && (
                                <ArrowUpIcon
                                  color="brand.primary"
                                  boxSize="6"
                                  p="0"
                                  transform="rotate(45deg)"
                                  position="absolute"
                                  right="-2px"
                                />
                              )}
                            </Flex>
                            <Text
                              textStyle="sm"
                              color="grey.500"
                              mt="1"
                              letterSpacing="less-tight"
                            >
                              {subItem.subTitle}
                            </Text>
                          </Link>
                        </Box>
                      ))}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            ))}
          </Accordion>
          <MobileUserMenu
            user={user}
            hasBottomBorder={!!regionSwitcherSettings}
          />
          {regionSwitcherSettings ? (
            <RegionSwitcherMobile
              regionalSwitcherSettings={regionSwitcherSettings}
            />
          ) : (
            <Flex
              borderTop="1px solid"
              borderColor="grey.100"
              alignItems="center"
              p="4"
            >
              <Flex
                bg="grey.50"
                borderRadius="8px"
                p="2"
                color={getTextColor(theme)}
                cursor="default"
              >
                <GlobeIcon boxSize="5" />
              </Flex>
              <Text as="span" ml="2" fontSize="sm" color="grey.600">
                {!user.isLoading && user.viewer?.info
                  ? 'You are viewing the US site'
                  : 'You are viewing the global site'}
              </Text>
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};

const overlayStyles = {
  position: 'absolute',
  top: '0',
  right: '0',
  left: '0',
  background: 'transparent',
} as const;

const stickyHeaderStyles = {
  position: 'sticky',
  top: '0',
  zIndex: 'sticky',
} as const;
