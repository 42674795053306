import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box as ChakraBox,
  Flex,
  Text as ChakraText,
} from '@chakra-ui/react';

import {
  ChevronDownIcon,
  ChevronUpIcon,
} from '@r-client/shared/ui/chakra-ui-icons';
import { Avatar, Box, Text } from '@r-client/shared/ui/core';

import { IssuerProfileContent } from '../issuer-profile-content/issuer-profile-content';
import type { THeaderUser } from '../types';
import { UserMobileNavRowContent } from './nav-items/user';

export interface IMobileUserNavigationProps {
  user: THeaderUser;
  onNotificationsClick?: VoidFunction;
}

export const MobileUserNavigation = ({
  user,
  onNotificationsClick,
}: IMobileUserNavigationProps) => {
  return (
    <Box>
      <Accordion allowToggle variant="republic">
        {!!user?.issuerProfiles.length && (
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  _hover={{ bg: 'white' }}
                  fontWeight="semibold"
                  px="4"
                  py="3.5"
                >
                  {user.issuerProfiles[0]?.offerings.length === 1 ? (
                    <Flex alignItems="center" flex="1">
                      <Avatar
                        url={user.issuerProfiles[0].offerings[0].logoUrl || ''}
                        avatarType="issuer"
                        border
                        size="xSmall"
                      />
                      <ChakraText
                        as="span"
                        fontSize="md"
                        fontWeight="semibold"
                        ml="4"
                      >
                        {user.issuerProfiles[0].companyName}
                      </ChakraText>
                      <ChakraBox ml="auto">
                        {isExpanded ? (
                          <ChevronUpIcon color="grey.600" boxSize="5" />
                        ) : (
                          <ChevronDownIcon color="grey.600" boxSize="5" />
                        )}
                      </ChakraBox>
                    </Flex>
                  ) : (
                    <Text>Your companies</Text>
                  )}
                </AccordionButton>
                <AccordionPanel p="0" display="flex">
                  <IssuerProfileContent
                    companyName={user.issuerProfiles[0]?.companyName || ''}
                    onboardingCompleteness={
                      user.issuerProfiles[0]?.onboardingCompleteness
                    }
                    offerings={user.issuerProfiles[0]?.offerings}
                  />
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        )}
        {user && (
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  _hover={{ bg: 'white' }}
                  fontWeight="semibold"
                  px="4"
                  py="3.5"
                >
                  <Flex alignItems="center" flex="1">
                    <Avatar
                      url={user.avatarUrl ?? undefined}
                      size="xSmall"
                      badge={user.isAdmin ? 'admin' : undefined}
                    />
                    <ChakraText
                      as="span"
                      fontSize="md"
                      fontWeight="semibold"
                      ml="4"
                    >
                      {user?.firstName}
                    </ChakraText>
                    <ChakraBox ml="auto">
                      {isExpanded ? (
                        <ChevronUpIcon color="grey.600" boxSize="5" />
                      ) : (
                        <ChevronDownIcon color="grey.600" boxSize="5" />
                      )}
                    </ChakraBox>
                  </Flex>
                </AccordionButton>
                <AccordionPanel p="0" display="flex">
                  <UserMobileNavRowContent
                    userSlug={user.slug}
                    notificationsCount={user.unreadNotificationsCount}
                    messagesCount={user.unreadMessagesCount}
                    editableOfferings={user.editableOfferings}
                    onNotificationsClick={onNotificationsClick}
                  />
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        )}
      </Accordion>
    </Box>
  );
};
